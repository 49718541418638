import { inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { usesConnectedThermometer } from '@app/cnst/hardware.cnst'
import { ROUTES } from '@app/cnst/nav.cnst'
import { NavigationParams } from '@app/cnst/nav.params.cnst'
import { QuizPage } from '@app/cnst/quiz-pages.cnst'
import { decorate, ErrorHandlerType, LoaderType } from '@app/decorators/decorators'
import { MockedTemperature } from '@app/model/test.model'
import { HardwareDevice } from '@app/reducers/hardwareDevice.reducer'
import { AdminService } from '@app/srv/admin.service'
import { getExtraHeaders, processBackendResponse } from '@app/srv/api.service'
import { di, runOutsideAngular } from '@app/srv/di.service'
import { storageService } from '@app/srv/storage.service'
import { dispatch, getState, StoreService, USER_SETTINGS } from '@app/srv/store.service'
import { urlUtil } from '@app/util/url.util'
import { NavController } from '@ionic/angular/standalone'
import {
  _Memo,
  _objectKeys,
  _randomInt,
  _range,
  _stringify,
  getFetcher,
  IsoDate,
  localDate,
  localTime,
  pHang,
  StringMap,
  topbar,
} from '@naturalcycles/js-lib'
import {
  AccountChangeHardwareInput,
  AppSettings,
  AvgCycleLengthOption,
  BackendResponseFM,
  BackendResponseFMResp,
  backendResponseReviver,
  BirthControlMethodBreakpointOption,
  ConceiveTimingBreakpointOption,
  ConsideringPurchaseOption,
  DiscussedFertilityOption,
  EligibleAppleWatchBreakpointOption,
  GoalBreakpointOption,
  HardwareDeviceTM,
  HardwareId,
  HormonalBirthControlHowLongBreakpointOption,
  HormonalBirthControlUsageBreakpointOption,
  PartnerInvolvementOption,
  PeriodRegularityOption,
  PersonaDataTM,
  PersonaName,
  ProductKey,
  QuizDataInput,
  shortQuizGoalOptions,
  SleepOption,
  SleepWithTrackerOption,
  StatementDescriptionOption,
  WearWatchToBedOption,
  WhichWearableBreakpointOption,
} from '@naturalcycles/shared'
import { env } from '@src/environments/environment'
import { AuthPageMode } from '@src/typings/auth'
import { AppQuizFlow } from '@src/typings/quiz.types'
import { appSettingsTMtoFM } from './appSettings.cnst'
import { AuthService } from './auth.service'
import { HardwareDeviceService } from './hardwareDevice.service'
import { OrderService } from './order.service'
import { storage3Service } from './storage3/storage3.service'
import { T3Service } from './t3.service'
import { UebeService } from './uebe.service'

export enum OuraDataType {
  NORMAL = 'NORMAL',
  ADJUSTED = 'ADJUSTED',
  SHORT_SLEEP = 'SHORT_SLEEP',
  EXCLUDED = 'EXCLUDED',
}

export interface SkipQuizOptions {
  fertilityGoal?: GoalBreakpointOption
  hwId?: HardwareId
  ownsHw?: boolean
}

let lagRadarDestroy: (() => void) | undefined

const qaApi = getFetcher({
  logRequest: true,
  logResponse: true,
  baseUrl: `${env.apiUrl}/qa`,
  credentials: 'include',
  timeoutSeconds: 120,
  jsonReviver: backendResponseReviver,
})
  .onBeforeRequest(async req => {
    const headers = await getExtraHeaders()
    Object.assign(req.init.headers, headers)

    topbar.show()
  })
  .onAfterResponse(async res => {
    if (!res.ok && res.fetchResponse?.status === 401) {
      alert('401 received, will redirect to login page..')
      di.get(AdminService).redirectToLogin()
      await pHang()
    }

    if (!res.ok) {
      alert(_stringify(res.err))
      return
    }

    if (res.body && res.req.responseType === 'json') {
      processBackendResponse(res.body)
    }

    topbar.hide()
  })

@Injectable({ providedIn: 'root' })
export class QAService {
  private storeService = inject(StoreService)
  private authService = inject(AuthService)
  private router = inject(Router)
  private orderService = inject(OrderService)

  private readonly isProduction = env.prod

  @_Memo()
  async getPersonas(): Promise<PersonaDataTM[]> {
    const r = await qaApi.get<any>(`personas`)
    return r?.personas
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async callAuthServiceHandleLogin(
    backendResponse: BackendResponseFM,
    shouldRedirectToQuiz: boolean,
  ): Promise<void> {
    await this.authService.handleLogin(backendResponse, shouldRedirectToQuiz)
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async loginToPersona(personaCode: string, randomAppearance?: boolean): Promise<void> {
    this.storeService.persistenceEnabled = false

    const { backendResponse } = await qaApi.get<BackendResponseFMResp>(
      `loginToPersona/${personaCode}`,
    )
    const { sessionId } = backendResponse

    const appearance =
      randomAppearance &&
      [
        PersonaName.REGINA,
        PersonaName.BORINA,
        PersonaName.DEMIANA,
        PersonaName.DEMI_MOORE,
        PersonaName.SOCIAL_SERENA,
      ].includes(personaCode as PersonaName)
        ? 1
        : _randomInt(1, 2)

    await this.storeService.persistStateNowAndDisablePersistence({
      sessionId,
      userSettings: {
        appearance,
        multipleSessions: null,
        pendingBluetoothSettings: {},
      },
    })

    location.reload()
    await pHang()
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async addDataToPersona(
    cycles: number,
    cycleDay: number,
    cycleLength = 30,
    measureRatio = 0.12,
    skipTrackers?: boolean,
  ): Promise<void> {
    await qaApi.put<BackendResponseFMResp>(
      `addDataToPersona/${cycles}/${cycleDay}/${cycleLength}/${measureRatio}/${skipTrackers}`,
    )
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async makePersonaPregnant(cycles: number, pregDate?: string): Promise<void> {
    pregDate ||= localDate.todayString()

    await qaApi.put<BackendResponseFMResp>(`makePersonaPregnant/${cycles}/${pregDate}`)
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async addRealPregnantData(): Promise<void> {
    await qaApi.put<BackendResponseFMResp>(`addPregnancyDataToPersona`)
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async setCompleteDate(completeDate: IsoDate): Promise<void> {
    await qaApi.put<BackendResponseFMResp>(`completeDate/${completeDate}`)
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async loginToAccount(idOrEmail: string): Promise<void> {
    const { sessionId, hwDevice, appSettings, hwChanges } = await qaApi.post<{
      sessionId: string
      hwDevice: HardwareDeviceTM | null
      appSettings: AppSettings | undefined
      hwChanges: StringMap<HardwareId> | undefined
    }>(`loginToAccount`, {
      json: {
        idOrEmail,
      },
    })

    this.storeService.persistenceEnabled = false
    await this.storeService.persistStateNowAndDisablePersistence({
      sessionId,
      hwDevice,
      appSettings: appSettings && appSettingsTMtoFM(appSettings),
      hwChanges,
    })

    // Remove query strings to avoid re-triggering in case of having "adminLoginToPersonalId"
    const url = urlUtil.buildUrl(window.location.pathname)
    window.history.replaceState(undefined, '', url)

    location.reload()
    return await pHang()
  }

  async createMessage(msgKey: string): Promise<void> {
    await qaApi.post<BackendResponseFMResp>(`createMessage/${msgKey}/`)
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async toggleHardwareId(hwId: HardwareId): Promise<void> {
    await qaApi.put<BackendResponseFMResp>(`accounts/changeHardware`, {
      baseUrl: env.apiUrl, // hack
      json: {
        hwId,
      } as AccountChangeHardwareInput,
    })
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async buyThermometer(key: ProductKey): Promise<void> {
    await qaApi.put<BackendResponseFMResp>(`buyThermometer`, {
      json: {
        key,
      },
    })
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async getAllHardwares(): Promise<void> {
    await qaApi.get<void>(`hardwares`)
  }

  async connectToOuraMockedAccount(): Promise<void> {
    await qaApi.put<BackendResponseFMResp>(`connectMockedOuraAccount`)
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async syncOuraData(
    nDays: number,
    endDate: IsoDate,
    dataType = OuraDataType.NORMAL,
  ): Promise<BackendResponseFM> {
    const { backendResponse } = await qaApi.put<BackendResponseFMResp>(`syncOuraData`, {
      json: {
        nDays,
        dataType,
        endDate,
      },
    })
    return backendResponse
  }

  @decorate({
    loaderType: LoaderType.BLOCKING,
    errorHandlerType: ErrorHandlerType.DIALOG,
  })
  async disconnectOuraAccount(): Promise<void> {
    await qaApi.post<BackendResponseFMResp>(`disconnectOuraAccount`)
  }

  async toggleLagRadar(): Promise<void> {
    if (lagRadarDestroy) {
      lagRadarDestroy()
      lagRadarDestroy = undefined
      storageService.remove('lagRadar')
    } else {
      const { lagRadar } = await import('@app/vendor/lag-radar')

      runOutsideAngular(() => {
        lagRadarDestroy = lagRadar({
          frames: 50, // number of frames to draw, more = worse performance
          speed: 0.0017, // how fast the sweep moves (rads per ms)
          size: 200, // outer frame px
          inset: 3, // circle inset px
          parent: document.getElementById('lagradar'), // DOM node to attach to
        })

        storageService.put('lagRadar', '1')
      })
    }
  }

  async addPartner(): Promise<BackendResponseFM> {
    return await qaApi.post('partners/add')
  }

  async loginToPartner(id: string): Promise<void> {
    this.storeService.persistenceEnabled = false

    const { backendResponse } = await qaApi.post<BackendResponseFMResp>(`partners/login/${id}`)
    const { sessionId, partnerAccount } = backendResponse

    this.storeService.dispatch('logout')
    await storage3Service.clear([USER_SETTINGS])

    await this.storeService.persistStateNowAndDisablePersistence({
      sessionId,
      partnerAccount,
    })

    location.reload()
    await pHang()
  }

  async bypassQuiz({
    fertilityGoal = GoalBreakpointOption.prevent,
    hwId,
    ownsHw,
  }: SkipQuizOptions): Promise<void> {
    dispatch('clearQuizState')
    const flow: AppQuizFlow = [] // future improvement: add full flows to make it possible to navigate back
    const mimimalQuizData: QuizDataInput = {
      name: 'Amalia',
      dateOfBirth: '1993-08-17' as IsoDate,
      fertilityGoal,
    }

    const commonQuizData: QuizDataInput = {
      medicalConditions: [],
      avgCycleLength: AvgCycleLengthOption.twentyOneToTwentyEight,
      periodRegularity: PeriodRegularityOption.canUsuallyPredict,
      lastPeriodDate: '2023-08-01' as IsoDate,
      cycleChanges: [],
      sleep: SleepOption.wakeUpOften,
      sleepWithTracker: SleepWithTrackerOption.neither,
      whichWearable: [],
      consideringPurchase: ConsideringPurchaseOption.no,
      wearWatchToBed: WearWatchToBedOption.no,
      statementDescription: StatementDescriptionOption.routinesAreImportant,
    }

    if (hwId === HardwareId.APPLE_WATCH) {
      flow.push([QuizPage.eligibleAppleWatch])
      commonQuizData.wearWatchToBed = WearWatchToBedOption.yes
      commonQuizData.eligibleAppleWatch = EligibleAppleWatchBreakpointOption.yes
      if (ownsHw) {
        commonQuizData.whichWearable = [WhichWearableBreakpointOption.appleWatch]
      }
    }

    if (hwId === HardwareId.OURA) {
      commonQuizData.consideringPurchase = ConsideringPurchaseOption.yesOuraRing
      if (ownsHw) {
        commonQuizData.whichWearable = [WhichWearableBreakpointOption.ouraRing]
      }
    }

    const preventQuizData: QuizDataInput = {
      ...commonQuizData,
      birthControlMethod: BirthControlMethodBreakpointOption.pill,
      sideEffects: [],
      hormonalBirthControlHowLong: HormonalBirthControlHowLongBreakpointOption.moreThanAYear,
    }

    const planQuizData: QuizDataInput = {
      ...commonQuizData,
      conceiveTiming: ConceiveTimingBreakpointOption.moreThanAYear,
      hormonalBirthControlUsage: HormonalBirthControlUsageBreakpointOption.no,
      discussedFertility: DiscussedFertilityOption.noDontPlanTo,
      partnerInvolvement: PartnerInvolvementOption.noPartner,
    }

    const quizData: QuizDataInput = {
      ...mimimalQuizData,
      ...(fertilityGoal === GoalBreakpointOption.prevent && preventQuizData),
      ...(fertilityGoal === GoalBreakpointOption.plan && planQuizData),
    }

    if (!shortQuizGoalOptions.includes(fertilityGoal)) {
      flow.push([QuizPage.deviceResult])
    }

    dispatch('updateQuizData', quizData)
    dispatch('replaceQuizFlow', flow)

    await di.get(NavController).navigateForward(ROUTES.AuthPage, {
      state: { [NavigationParams.AUTH_PAGE_MODE]: AuthPageMode.Signup },
    })
    return
  }

  public async setSubscriptionBillingDates(input: {
    firstBillingDate: IsoDate
    nextBillingDate: IsoDate
  }): Promise<void> {
    const invalidDates = _objectKeys(input).filter(k => !localDate.isValid(input[k]))

    if (invalidDates.length) {
      const errorString = invalidDates.map(k => `${k}: ${input[k]}`).join('\n')
      alert(`The following dates are invalid:\n${errorString}`)
      return
    }

    await qaApi.put<BackendResponseFMResp>(`subscription`, {
      json: input,
    })
  }

  public pairFakeThermometer(): void {
    if (!usesConnectedThermometer()) return
    const { hwId } = getState().account

    const fakeThermometer: HardwareDevice = {
      mac: '00:00:00:00:00:00',
      buzzer: true,
      backlight: hwId === HardwareId.UEBE_THERMOMETER,
      testMeasurementDone: false,
      created: localTime.now().unix,
    }

    if (hwId === HardwareId.T3_THERMOMETER) {
      fakeThermometer.fwVersion = '4.10.2'
      fakeThermometer.serialNumber = 'fakeSerialNumber'
    }

    void di.get(HardwareDeviceService).saveHardwareDevice(fakeThermometer, hwId)
  }

  public mockThermometerTemperatures(temperatures: MockedTemperature[]): void {
    const { hwId } = getState().account

    if (hwId === HardwareId.UEBE_THERMOMETER) {
      return void di.get(UebeService).mockTemperatures(temperatures)
    }

    if (hwId === HardwareId.T3_THERMOMETER) {
      return void di.get(T3Service).mockTemperatures(temperatures)
    }
  }

  public generateThermometerTemperatures(): void {
    const { hwId } = getState().account

    const counter = _range(Math.floor(Math.random() * 6) + 4)
    const temperatures = counter.map(index => {
      const date = localDate.today().minus(index, 'day').toISODate()
      return { date }
    })

    if (hwId === HardwareId.UEBE_THERMOMETER) {
      return void di.get(UebeService).mockTemperatures(temperatures)
    }

    if (hwId === HardwareId.T3_THERMOMETER) {
      return void di.get(T3Service).mockTemperatures(temperatures)
    }
  }

  public generateThermometerTemperaturesSameDay(): void {
    const { hwId } = getState().account

    const counter = _range(Math.floor(Math.random() * 6) + 2)
    const temperatures = counter.flatMap(index => {
      const date = localDate.today().minus(index, 'day').toISODate()
      const dateCounter = _range(Math.floor(Math.random() * 3) + 1)
      return dateCounter.map(() => ({ date }))
    })

    if (hwId === HardwareId.UEBE_THERMOMETER) {
      return void di.get(UebeService).mockTemperatures(temperatures)
    }

    if (hwId === HardwareId.T3_THERMOMETER) {
      return void di.get(T3Service).mockTemperatures(temperatures)
    }
  }

  public triggerPayment(nonce?: string): void {
    if (this.isProduction) return
    if (this.router.url !== ROUTES.PaymentPage) {
      alert('You can only trigger payment from the payment page')
      return
    }

    if (!nonce) {
      nonce = prompt('Enter nonce', 'fake-valid-nonce') || ''
      if (!nonce) return
    }

    this.orderService.redDotNonce$.next(nonce)
  }
}
